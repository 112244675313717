import {
  createContext,
  ReactNode,
  useCallback,
  useState,
  useContext
} from 'react';

import Alert from '@new-efarm/components/alert';

interface ValueAlertContext {
  showAlert: (message: string) => void;
}

export const AlertContext = createContext<ValueAlertContext>({
  showAlert: () => {}
});

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = useCallback(() => {
    setShow(false);
    setMessage('');
  }, []);

  const handleShow = useCallback((msg: string) => {
    setMessage(msg);
    setShow(true);
  }, []);

  return (
    <AlertContext.Provider value={{ showAlert: handleShow }}>
      {show && <Alert message={message} onClose={handleClose} />}
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const { showAlert } = useContext(AlertContext);

  return {
    showAlert
  };
};
