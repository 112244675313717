import { memo, useState, useEffect, useCallback } from 'react';

import IconError from '@new-efarm/assets/icons/icon-error.svg';
import IconCloseRed from '@new-efarm/assets/icons/icon-close-red.svg';

import { Card, Image, Button, Desc } from './styles';

interface AlertProps {
  onClose: () => void;
  message?: string;
}

let timeOutTriggerCloseAuto: ReturnType<typeof setTimeout>;

const Index = ({ onClose, message }: AlertProps) => {
  const [className, setClassName] = useState('');

  const onCloseAlert = useCallback(() => {
    setClassName('');
    clearTimeout(timeOutTriggerCloseAuto);
    setTimeout(() => {
      onClose();
    }, 500);
  }, [onClose]);

  useEffect(() => {
    setTimeout(() => {
      setClassName('show');
    }, 100);

    timeOutTriggerCloseAuto = setTimeout(() => {
      onCloseAlert();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={className}>
      <Image src={IconError} alt="icon-error" />

      <Desc>{message}</Desc>

      <Button onClick={onCloseAlert}>
        <Image src={IconCloseRed} alt="icon-close-red" />
      </Button>
    </Card>
  );
};

export default memo(Index);
