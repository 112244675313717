import styled from 'styled-components';

export const Card = styled.div`
  background-color: #fff1f2;
  border-radius: 8px;
  box-shadow: 0px 4px 6px -1px #0000001a;
  border-left: 4px solid #e11d48;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  height: 84px;
  z-index: 200;
  position: absolute;
  top: -84px;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: calc(100% - 16px - 16px);
  transition: all 0.5s ease;

  &.show {
    top: 56px;
  }
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
`;

export const Button = styled.button`
  border: none;
  background: none;
`;

export const Desc = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #e11d48;
`;
