import AuthContext from '@auth/context/auth';
import classNames from 'classnames';
import config from 'config';
import FlagsProvider from 'contexts/FlagContext';
import { SnackbarProvider } from 'contexts/SnackbarContext';
import { defaultValuesFlagsContext } from 'libs/flags';
import queryClient from 'libs/queryClient';
import { lazy, useEffect, useMemo } from 'react';
import { QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import { getDummyState } from '../store/acquisition';
// Assets
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles/onefish.scss';
// Init tag manager
import DownloadPrompt from 'components/reusable/DownloadPrompt';
import { Snackbar } from 'components/reusable/snackbar';
import { AlertProvider } from 'contexts/AlertContext';
import { getAnalytics } from 'firebase/analytics';
import { isDownloadPromptVisible, useFlags } from 'libs/helpers';
import {
  initializeAppsFlyer,
  initializeCleverTap,
  initializeFbPixel,
  initializeGtm
} from '../libs/analytics';
import { firebase } from '../libs/firebase';
import { ClevertapProvider } from 'features/clevertap-popup/context/index';
import useQueryString from 'hooks/useQueryString';
import { getToolsbudToken } from '@cultivation-records/store/reportExternal';

// Pages
const NewDashboard = lazy(() => import('components/dashboard/NewDashboard'));
const Otp = lazy(() => import('@auth/pages/otp'));
const LocationPicker = lazy(() => import('pages/LocationPicker'));
const Maps = lazy(() => import('@maps/Maps'));
const SuccessPage = lazy(() => import('pages/SuccessPage'));
const Questionnaire = lazy(() => import('@auth/pages/questionnaire'));
const LoginUsername = lazy(() => import('@auth/pages/login-username'));
const LoginPhone = lazy(() => import('@auth/pages/login-phone'));
const RegisterPhone = lazy(() => import('@auth/pages/register'));

// lazy
const MaintenancePage = lazy(() => import('pages/MaintenancePage'));
const Onboarding = lazy(() => import('@app-onboarding/pages/onboarding'));
const MyPondsRouter = lazy(() => import('@my-ponds/routers'));
const CultivationRecordsRouter = lazy(() =>
  import('@cultivation-records/routers')
);
const CultivationRecordsExternalRouter = lazy(() =>
  import('@cultivation-records/routers/routesExternal')
);
const MarketplaceRouterGuestMode = lazy(() =>
  import('@marketplace/routes/routesGuestMode')
);
const CultivationGPTRouter = lazy(() => import('@cultivation-gpt/routers'));

//new efarm
import { AlertProvider as NewAlertProvider } from '@new-efarm/contexts/useAlertContext';

//new eFarm - Page
const NewLogin = lazy(() => import('@new-efarm/pages/login'));
const ForgotPassword = lazy(() => import('@new-efarm/pages/forgot-password'));
const ChangePassword = lazy(() => import('@new-efarm/pages/change-password'));
const Profile = lazy(() => import('@new-efarm/pages/profile'));

const App = () => {
  getAnalytics(firebase);
  initializeGtm();
  initializeFbPixel();
  initializeAppsFlyer();
  initializeCleverTap();

  return (
    <QueryClientProvider client={queryClient}>
      <FlagsProvider defaults={defaultValuesFlagsContext}>
        <AuthContext.Provider>
          <SnackbarProvider>
            <AlertProvider>
              <NewAlertProvider>
                <ClevertapProvider>
                  <Router>
                    <MaintenancePageProvider>
                      <Switch>
                        <Route
                          exact
                          path="/location-picker"
                          render={(props) => (
                            <>
                              <LocationPicker {...props} />
                            </>
                          )}
                        />
                        <Route
                          exact
                          path="/maps"
                          render={(props) => (
                            <>
                              <Maps {...props} />
                            </>
                          )}
                        />

                        {/* new eFarm */}
                        <Route
                          exact
                          path="/new-login"
                          render={() => (
                            <>
                              <NewLogin />
                              <DownloadPrompt />
                            </>
                          )}
                        />
                        <Route
                          exact
                          path="/forgot-password"
                          render={() => (
                            <>
                              <ForgotPassword />
                              <DownloadPrompt />
                            </>
                          )}
                        />

                        <Route
                          exact
                          path="/change-password"
                          render={() => (
                            <>
                              <ChangePassword />
                              <DownloadPrompt />
                            </>
                          )}
                        />

                        <Route
                          exact
                          path="/profile"
                          render={() => (
                            <>
                              <Profile />
                              <DownloadPrompt />
                            </>
                          )}
                        />
                        {/* --- /new eFarm ----- */}

                        <Route
                          exact
                          path="/otp"
                          render={(props) => (
                            <>
                              <Otp history={props.history} />
                              <DownloadPrompt />
                            </>
                          )}
                        />
                        <Route
                          exact
                          path="/login/username"
                          render={(props) => (
                            <>
                              <LoginUsername {...props} />
                              <DownloadPrompt />
                            </>
                          )}
                        />
                        <Route
                          exact
                          path="/login/phone"
                          render={(props) => (
                            <>
                              <LoginPhone {...props} />
                              <DownloadPrompt />
                            </>
                          )}
                        />
                        <Route
                          exact
                          path="/register"
                          render={(props) => (
                            <>
                              <RegisterPhone {...props} />
                              <DownloadPrompt />
                            </>
                          )}
                        />
                        <Route
                          exact
                          path="/success-page"
                          render={(props) => (
                            <>
                              <SuccessPage history={props.history} />
                            </>
                          )}
                        />
                        <Route
                          exact
                          path="/questionnaire"
                          render={(props) => (
                            <>
                              <Questionnaire
                                location={props.location}
                                history={props.history}
                                quessionaireUrl={props.match.url}
                              />
                            </>
                          )}
                        />
                        <LoginGate />
                      </Switch>
                    </MaintenancePageProvider>
                  </Router>
                </ClevertapProvider>
              </NewAlertProvider>
            </AlertProvider>
          </SnackbarProvider>
        </AuthContext.Provider>
      </FlagsProvider>
    </QueryClientProvider>
  );
};

const MaintenancePageProvider = ({ children }) => {
  const isMaintenanceEnabled =
    process.env.REACT_APP_MAINTENANCE_MODE === 'true';
  if (isMaintenanceEnabled) {
    return (
      <>
        <Route exact path="/maintenance" render={() => <MaintenancePage />} />
        <Redirect from="/*" to="/maintenance" />
      </>
    );
  } else {
    return children;
  }
};

const LoginGate = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => {
    return location?.search;
  }, [location]);
  const utmRoute = new URLSearchParams(params).get('utm_route');

  const queryString = useQueryString();
  const token = queryString.get('token');
  const toolsbudToken = getToolsbudToken();

  const {
    isMyPondsEnabled,
    isCultivationRecordsEnabled,
    isMarketplaceUsingGuestMode,
    isInternalModeEnabled
  } = useFlags();
  const isInProhibitedPage = config?.downloadBar?.prohibitedRoutes?.some(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    (route) => useRouteMatch(route)
  );

  const auth = AuthContext.useContainer();
  const dummyStorageState = getDummyState();
  const isDummy = dummyStorageState || auth?.user?.is_trial;
  const leadsName = auth?.user?.name;
  const acquisitionName = leadsName || 'Hai, pembudidaya!';

  useEffect(() => {
    //check if deeplink has marketplace utm route
    if (location?.pathname === '/') {
      if (utmRoute?.includes('/marketplace')) {
        history.push(utmRoute);
      }
    }
  }, []);

  useEffect(() => {
    if (auth.isInternalMode === true && !auth.isLoggedIn) {
      auth.setGreetingPopup(true);
    } else {
      auth.setGreetingPopup(false);
    }
  }, [auth.isLoggedIn]);

  const handleHidePopup = () => {
    auth.setGreetingPopup(false);
  };

  const isMarketplace = useMemo(() => {
    return location?.pathname?.includes('/marketplace');
  }, [location]);

  const isCultivation = useMemo(() => {
    return location?.pathname?.includes('/cultivation');
  }, [location]);

  if (
    isMarketplaceUsingGuestMode &&
    isMarketplace &&
    !auth?.user &&
    !auth.isFetchLeadLoading
  ) {
    return <MarketplaceRouterGuestMode />;
  }

  if (
    isCultivation &&
    !auth?.user &&
    !auth.isFetchLeadLoading &&
    (!!token || !!toolsbudToken)
  ) {
    return <CultivationRecordsExternalRouter />;
  }

  return (
    <div
      className={classNames(
        'App wrapper h-100',
        isDownloadPromptVisible && !isInProhibitedPage
          ? 'fixed-bottom-margin'
          : ''
      )}
    >
      {auth.user || isDummy ? (
        <>
          <NewDashboard
            acquisitionName={acquisitionName}
            auth={auth}
            isDummy={isDummy}
            history={history}
          />

          {isMyPondsEnabled && <MyPondsRouter />}
          {isCultivationRecordsEnabled && <CultivationRecordsRouter />}
          <CultivationGPTRouter />
        </>
      ) : (
        <Onboarding />
      )}
      <Snackbar hideCloseButton />
      <DownloadPrompt />
    </div>
  );
};

export default App;
